import { useMainStore } from '~/store/main/main.store'

export function getOrderUrl() {
  const ctx = useNuxtApp()
  const $router = useRouter()
  const mainStore = useMainStore()

  const client = ctx._apolloClients.default
  const getCommonHeaders = computed(() => mainStore.getCommonHeaders)

  const getUrl = async () => {
    const res = (
      await client.query({
        query: gql`
          query getShortUid($byShortUid: String) {
            personal {
              getInteractionOrders(byShortUid: $byShortUid) {
                shortUid
              }
            }
          }
        `,
        variables: {
          byShortUid: null,
        },
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.personal.getInteractionOrders?.[0]?.shortUid
    nextTick(() => {
      if (res) $router.push({ path: `/personal/order/${res}/` })
      else $router.push({ path: `/personal/complete/` })
    })
  }

  return getUrl()
}
